<template>
  <q-page
    class="column items-center justify-center q-pa-md"
    style="height: 100vh"
  >
    <div>
      <img
        class="q-pa-auto"
        :src="`./img/diagram.png`"
        alt="French Food"
        style="
          width: 325px;
          height: 325px;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        "
      />
      <!-- usemap="#foodmap" -->

      <!-- <map name="foodmap" style="position: relative">
        <area
          shape="poly"
          coords="161,155,161,42,130,46,104,57,83,73,62,97"
          alt="Croissant"
          class="cursor-pointer"
          style="position: relative"
          @click="postMSG('4')"
        />
        <area
          shape="poly"
          coords="162,43,192,46,219,57,243,74,262,97,162,155"
          alt="Croissant"
          class="cursor-pointer"
          style="position: relative"
          @click="postMSG('5')"
        />
        <area
          shape="poly"
          coords="162,156,263,97,276,126,281,154,279,184,268,216"
          alt="Croissant"
          class="cursor-pointer"
          style="position: relative"
          @click="postMSG('3')"
        />
        <area
          shape="poly"
          coords="161,156,267,217,248,245,224,264,195,276,161,281"
          alt="Croissant"
          class="cursor-pointer"
          style="position: relative"
          @click="postMSG('1')"
        />
        <area
          shape="poly"
          coords="158,157,159,280,122,275,98,263,74,244,54,217"
          alt="Croissant"
          class="cursor-pointer"
          style="position: relative"
          @click="postMSG('5')"
        />
        <area
          shape="poly"
          coords="60,98,157,155,53,214,43,181,41,156,46,132"
          alt="Croissant"
          class="cursor-pointer"
          style="position: relative"
          @click="postMSG('2')"
        />
      </map> -->
      <svg
        height="325"
        width="325"
        style="
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        "
      >
        <polygon
          class="ripple"
          id="slice1"
          points="161,154 162,42 131,47 105,58"
          style="
            fill: grey;
            stroke: transparent;
            stroke-width: 1;
            position: absolute;
            opacity: 0;
          "
          @click="postMSG('4', 1)"
        />
        <polygon
          class="ripple"
          id="slice2"
          points="161,155 105,57 82,74 62,98"
          style="
            fill: grey;
            stroke: transparent;
            stroke-width: 1;
            position: absolute;
            opacity: 0;
          "
          @click="postMSG('4', 2)"
        />
        <polygon
          class="ripple"
          id="slice3"
          points="160,155 61,98 49,124 43,156"
          style="
            fill: grey;
            stroke: transparent;
            stroke-width: 1;
            position: absolute;
            opacity: 0;
          "
          @click="postMSG('2', 3)"
        />
        <polygon
          class="ripple"
          id="slice4"
          points="161,156 42,157 45,186 56,217"
          style="
            fill: grey;
            stroke: transparent;
            stroke-width: 1;
            position: absolute;
            opacity: 0;
          "
          @click="postMSG('2', 4)"
        />
        <polygon
          class="ripple"
          id="slice5"
          points="161,156 56,218 75,244 99,264"
          style="
            fill: grey;
            stroke: transparent;
            stroke-width: 1;
            position: absolute;
            opacity: 0;
          "
          @click="postMSG('5', 5)"
        />
        <polygon
          class="ripple"
          id="slice6"
          points="161,156 99,265 127,276 161,281"
          style="
            fill: grey;
            stroke: transparent;
            stroke-width: 1;
            position: absolute;
            opacity: 0;
          "
          @click="postMSG('5', 6)"
        />
        <polygon
          class="ripple"
          id="slice7"
          points="161,158 162,281 193,277 224,264"
          style="
            fill: grey;
            stroke: transparent;
            stroke-width: 1;
            position: absolute;
            opacity: 0;
          "
          @click="postMSG('1', 7)"
        />
        <polygon
          class="ripple"
          id="slice8"
          points="161,156 225,264 247,246 268,217"
          style="
            fill: grey;
            stroke: transparent;
            stroke-width: 1;
            position: absolute;
            opacity: 0;
          "
          @click="postMSG('1', 8)"
        />
        <polygon
          class="ripple"
          id="slice9"
          points="162,156 268,216 278,190 281,157"
          style="
            fill: grey;
            stroke: transparent;
            stroke-width: 1;
            position: absolute;
            opacity: 0;
          "
          @click="postMSG('3', 9)"
        />
        <polygon
          class="ripple"
          id="slice10"
          points="162,155 281,156 276,127 262,98"
          style="
            fill: grey;
            stroke: transparent;
            stroke-width: 1;
            position: absolute;
            opacity: 0;
          "
          @click="postMSG('3', 10)"
        />
        <polygon
          class="ripple"
          id="slice11"
          points="161,155 261,97 243,74 219,57"
          style="
            fill: grey;
            stroke: transparent;
            stroke-width: 1;
            position: absolute;
            opacity: 0;
          "
          @click="postMSG('5', 11)"
        />
        <polygon
          class="ripple"
          id="slice12"
          points="162,152 218,56 192,46 163,43"
          style="
            fill: grey;
            stroke: transparent;
            stroke-width: 1;
            position: absolute;
            opacity: 0;
          "
          @click="postMSG('5', 12)"
        />
      </svg>
    </div>

    <!-- <area target="" alt="" title="" href="" coords="161,154,162,42,131,47,105,58" shape="poly">
    <area target="" alt="" title="" href="" coords="161,155,105,57,82,74,62,98" shape="poly">
    <area target="" alt="" title="" href="" coords="160,155,61,98,49,124,43,156" shape="poly">
    <area target="" alt="" title="" href="" coords="161,156,42,157,45,186,56,217" shape="poly">
    <area target="" alt="" title="" href="" coords="161,156,56,218,75,244,99,264" shape="poly">
    <area target="" alt="" title="" href="" coords="161,156,99,265,127,276,161,281" shape="poly">
    <area target="" alt="" title="" href="" coords="161,158,162,281,193,277,224,264" shape="poly">
    <area target="" alt="" title="" href="" coords="161,156,225,264,247,246,268,217" shape="poly">
    <area target="" alt="" title="" href="" coords="162,156,268,216,278,190,281,157" shape="poly">
    <area target="" alt="" title="" href="" coords="162,155,281,156,276,127,262,98" shape="poly">
    <area target="" alt="" title="" href="" coords="161,155,261,97,243,74,219,57" shape="poly">
    <area target="" alt="" title="" href="" coords="162,152,218,56,192,46,163,43" shape="poly"> -->
  </q-page>
</template>

<script>
// @ is an alias to /src
import HelloWorld from "@/components/HelloWorld.vue";
import { onMounted } from "vue";
export default {
  name: "HomeView",
  components: {
    HelloWorld,
  },
  setup() {
    let postMSG = (msg, id) => {
      document.getElementById(`slice${id}`).style.opacity = 0.3;
      androidApp.toastText(msg);
    };

    let logPlace = () => {
      console.log("clicked");
    };

    onMounted(() => {
      // document.addEventListener("click", (event) => {
      //   console.log(`Mouse X: ${event.clientX}, Mouse Y: ${event.clientY}`);
      // });
    });

    return { postMSG, logPlace };
  },
};
</script>
<style lang="scss">
.ripple {
  position: relative;
  background-color: #04aa6d;
  border: none;
  font-size: 28px;
  color: #ffffff;
  text-align: center;
  -webkit-transition-duration: 0.4s; /* Safari */
  transition-duration: 0.4s;
  text-decoration: none;
  overflow: hidden;
  cursor: pointer;
}

.ripple:after {
  content: "";
  background: #90ee90;
  display: block;
  position: absolute;
  padding-top: 300%;
  padding-left: 350%;
  margin-left: -20px !important;
  margin-top: -120%;
  opacity: 0;
  transition: all 0.8s;
}

.ripple:active:after {
  padding: 0;
  margin: 0;
  opacity: 1;
  transition: 0s;
}
</style>
